import { container, primaryColor } from "assets/jss/nextjs-material-kit.js";

const footerStyle = {
  block: {
    color: "inherit",
    padding: "0 2vw",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block"
  },
  left: {
    float: "left!important",
    display: "block"
  },
  center: {
    
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right!important"
  },
  footer: {
    // padding: "0.9375rem 0",
    // textAlign: "center",
    // display: "flex",
    // zIndex: "2",
    // position: "relative",
    position: "fixed",
    zIndex: 1100,
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: "center",
    backgroundColor: "white",
    visibility: "hidden"
  },
  footerOn: {
    animation: `$fadeIn .5s`,
    animationFillMode: "forwards",
  },
  footerOff: {
    animation: `$fadeOut .5s`,
    animationFillMode: "forwards",

  },
  "@keyframes fadeIn": {
    from: {
      opacity: "0",
      visibility: "visible"
    },
    to: {
      opacity: "1",
      visibility: "visible"
  }
 },
 "@keyframes fadeOut": {
  from: { opacity: "1",
          visibility: "visible"
  },
  to: { 
    opacity: "0",
    visibility: "hidden"
   }
  },
 navLink: {
    padding: "3px 30px",
  },
  copyright: {
    fontSize: "small",
  },
  a: {
    color: primaryColor,
    textDecoration: "none",
    backgroundColor: "transparent"
  },
  footerWhiteFont: {
    "&,&:hover,&:focus": {
      color: "#FFFFFF"
    }
  },
  container,
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  icon: {
    width: "18px",
    height: "18px",
    position: "relative",
    top: "3px"
  }
};
export default footerStyle;
