/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "components/CustomButtons/Button.js";

import Link from 'next/link';

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import styles from "assets/jss/nextjs-material-kit/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { domainInfo, whiteFont } = props;

  React.useEffect(() => {
    window.addEventListener("scroll", footerOpacityChange);
    return function cleanup() {
        window.removeEventListener("scroll", footerOpacityChange);
    }
  });

  const footerOpacityChange = () => {
      var scrollPct = ((document.documentElement.scrollTop + document.body.scrollTop) / (document.documentElement.scrollHeight - document.documentElement.clientHeight) * 100)

      if (scrollPct > 85) {
        document.body
          .getElementsByTagName("footer")[0]
          .classList.remove(classes.footerOff);
        document.body
          .getElementsByTagName("footer")[0]
          .classList.add(classes.footerOn);
      } else {
        document.body
          .getElementsByTagName("footer")[0]
          .classList.add(classes.footerOff);
        document.body
          .getElementsByTagName("footer")[0]
          .classList.remove(classes.footerOn);
      }
    }

  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerOff]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });

  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div> 
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <Link href='/privacy-policy'>
                <a
                  href="/privacy-policy"
                  className={classes.block}
                  target="_blank"
                >
                  Privacy Policy
                </a>
              </Link>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Link href='/terms-of-service'>
                <a
                  href="/terms-of-service"
                  className={classes.block}
                  target="_blank"
                >
                  Terms Of Service
                </a>
              </Link>
            </ListItem>
          {/* </List>
        </div>
        <div className={classes.center}>
          <List> */}
          <div>
            <ListItem className={classes.inlineBlock}>
              <Tooltip
                id="instagram-facebook"
                title="Follow us on facebook"
                rel="noreferrer"
                placement={"top"}
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  color="transparent"
                  href={"https://www.facebook.com/www.mylove.fitness/"}
                  target="_blank"
                  rel="noreferrer"
                  className={classes.navLink}
                >
                  <i className={classes.socialIcons + " fab fa-facebook"} />
                </Button>
              </Tooltip>
            </ListItem>
            {/* <ListItem className={classes.inlineBlock}>
              <Tooltip
                id="instagram-tooltip"
                title="Follow us on instagram"
                placement={"top"}
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  color="transparent"
                  rel="noreferrer"
                  href={domainInfo?.instagramURL ? domainInfo?.instagramURL : "https://www.instagram.com/kornelia222"}
                  target="_blank"
                  className={classes.navLink}
                >
                  <i className={classes.socialIcons + " fab fa-instagram"} />
                </Button>
              </Tooltip>
            </ListItem> */}
            </div>
          </List> 
        </div>
        <div className={classes.copyright}>
          &copy; {1900 + new Date().getYear()} , made with{" "}
          <Favorite onClick={() => { console.log(__NEXT_DATA__.buildId)} } className={classes.icon} /> to make a better world. Powered by <a href="https://www.mylove.fitness/">www.mylove.fitness</a>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
